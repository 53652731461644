<template>
  <div class="block">

    <div class="columns marginless">
      <div class="section-header label-field column">
        Verification call information
      </div>
    </div>

    <div class="columns marginless">
      <div class="label-field column is-3">
        Name
      </div>
      <div class="column is-9">
        {{ verifiersName }}
      </div>
    </div>

    <div class="columns marginless">
      <div class="label-field column is-3">
        Position
      </div>
      <div class="column is-9">
        {{ verifiersPosition }}
      </div>
    </div>

    <div class="columns marginless">
      <div class="label-field column is-3">
        Phone number
      </div>
      <div class="column is-9">
        {{ verifiersPhoneNumber }}
      </div>
    </div>

    <div class="columns marginless">
      <div class="label-field column is-3">
        Date of call
      </div>
      <div class="column is-9">
        {{ verificationDate }}
      </div>
    </div>

    <hr>

  </div>
</template>

<script>

import { format, parseISO } from 'date-fns';

export default {
  name: "AchRequestVerificationCallInfo",
  computed: {
    verifiersName() {
      let value;
      if (this.$store.state.ach.achData) {
        value = this.$store.state.ach.achData.verifiersName;
      }
      return value;
    },
    verifiersPosition() {
      let value;
      if (this.$store.state.ach.achData) {
        value = this.$store.state.ach.achData.verifiersPosition;
      }
      return value;
    },
    verifiersPhoneNumber() {
      let value;
      if (this.$store.state.ach.achData) {
        value = this.$store.state.ach.achData.verifiersPhoneNumber;
      }
      return value;
    },
    verificationDate() {
      let value;
      if (this.$store.state.ach.achData && this.$store.state.ach.achData.verificationDate != '0001-01-01T00:00:00') {
        value = format(parseISO(this.$store.state.ach.achData.verificationDate), 'MM/dd/yyyy');
      } else {
        value = null;
      }
      return value;
    },
  },
};
</script>
