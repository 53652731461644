<template>
  <div class="block">

    <div v-if="['AchAdministrator'].includes(this.curUserType)">
      <!-- <div class="block"> -->
      <div class="columns marginless">
        <div class="label-field column is-3">
          Attached voided check/bank letter
        </div>
        <div class="column is-9">
          <div class="control">
            <button
              v-if="!checkIsShowing"
              class="button is-primary"
              :class="{ 'is-loading': checkIsLoading }"
              @click.prevent="viewVoidedCheck"
            >
              Show
            </button>
            <button
              v-if="checkIsShowing"
              class="button is-primary"
              :class="{ 'is-loading': checkIsLoading }"
              @click.prevent="hideVoidedCheck"
            >
              Hide
            </button>
          </div>
        </div>
      </div>
      <div
        v-if="checkIsShowing && responseType == 'jpg'"
        class="photo-div"
      >
        <img
          :src="imageUrl"
        >
      </div>

    </div>

  </div>
</template>

<script>

export default {
  name: "AchRequestBankImage",
  props: {
    achId: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      checkIsLoading: false,
      checkIsShowing: false,
      bankData: null,
      imageUrl: null,
      responseType: null,
      pdfUrl: null,
    };
  },
  methods: {
    async viewVoidedCheck() {
      this.checkIsLoading = true;
      let query = {
        humanReadableId: this.achId,
      };
      const data = await this.$store.dispatch('ach/getVoidedCheck', query);
      this.$emit('image-viewed');
      // console.log('data:', data);
      let blob;
      if (data.headers['content-type'] === 'application/pdf') {
        this.responseType = 'pdf';
        blob = new Blob( [ data.data ], { type: "application/pdf" } );
        let fileURL = URL.createObjectURL(blob);
        window.open(fileURL, '_blank');
        this.checkIsLoading = false;
      } else {
        this.responseType = 'jpg';
        blob = new Blob( [ data.data ], { type: "image/jpeg" } );
        var urlCreator = window.URL || window.webkitURL;
        this.imageUrl = urlCreator.createObjectURL(blob);
        this.checkIsLoading = false;
        this.checkIsShowing = true;
      }
    },
    hideVoidedCheck() {
      this.checkIsLoading = false;
      this.checkIsShowing = false;
    },
  },
};
</script>

<style>

.photo-div {
  max-width: 400px;
  /* height: 400px; */
}

</style>
