<template>
  <div class="container">

    <overview :achId="achId" />
    <vendor-submitted />
    <verification-call-info />

    <financial-information
      v-if="['AchAdministrator'].includes(this.curUserType)"
      :achId="achId"
      @show-bank-info="showBankInfoSuccess"
      @image-viewed="imageViewedSuccess"
    />

    <div
      v-if="status === 'Rejected-pending Finance Review' && ['AchAdministrator'].includes(this.curUserType)"
      class="block"
    >
      <ValidationObserver
        v-slot="validation"
        tag="div"
      >
        <input-form :errors="validation.errors">

          <div
            class="field columns"
          >
            <div class="column is-8">
              <vee-radio
                v-model="closeOrSendBack"
                :options="closeOrSendBackOptions"
                numOfColumns="2"
                title="Close or send back"
                name="Close or send back"
                rules="required"
              />
            </div>

            <div class="column is-6">
              <vee-text-area
                v-if="closeOrSendBack === 'sendBack'"
                v-model="note"
                label="Note"
                name="Note"
                placeholder="Note"
                :rules="sendBackRules"
              />
            </div>
          </div>

          <div class="control margin-top-sm">
            <button
              class="button is-primary"
              :class="{ 'is-loading': submitIsLoading }"
              @click.prevent="submitForm(validation)"
            >
              Submit
            </button>
          </div>

        </input-form>
      </ValidationObserver>
    </div>
    <audit-trail />

  </div>
</template>

<script>

import Overview from '@/pages/ach-request/sections/Overview';
import VendorSubmitted from '@/pages/ach-request/sections/VendorSubmitted';
import FinancialInformation from '@/pages/ach-request/sections/FinancialInformation';
import VerificationCallInfo from '@/pages/ach-request/sections/VerificationCallInfo';
import AuditTrail from '@/pages/ach-request/sections/AuditTrail';

import {
  TextArea,
  Radio,
} from '@phila/phila-ui';

import {
  extend,
  withValidation,
  ValidationObserver,
  setInteractionMode,
} from 'vee-validate';

import { required, email, ext, numeric, digits, is } from 'vee-validate/dist/rules';
const VeeTextArea = withValidation(TextArea);
const VeeRadio = withValidation(Radio);


export default {
  name: "AchRequestDenial",
  components: {
    Overview,
    VendorSubmitted,
    FinancialInformation,
    VerificationCallInfo,
    AuditTrail,
    VeeRadio,
    VeeTextArea,
  },
  props: {
    achId: {
      type: String,
      default: '',
    },
    status: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      closeOrSendBack: null,
      submitIsLoading: false,
      note: null,
    };
  },
  computed: {
    closeOrSendBackOptions() {
      let value = {
        'close': 'Close request',
        'sendBack': 'Send back to department',
      };
      return value;
    },
    sendBackRules() {
      let value;
      if (this.closeOrSendBack === 'sendBack') {
        value = 'required';
      } else {
        value = '';
      }
      return value;
    },
    // closeRules() {
    //   let value;
    //   if (this.closeOrSendBack === 'close') {
    //     value = 'required';
    //   } else {
    //     value = '';
    //   }
    //   return value;
    // },
    // noSelectionRules() {
    //   let value;
    //   if (this.closeOrSendBack == null) {
    //     value = 'required';
    //   } else {
    //     value = '';
    //   }
    //   return value;
    // },
  },
  created() {
    extend('required', {
      ...required,
      message: 'The {_field_} field is required',
    });
  },
  methods: {
    showBankInfoSuccess() {
      this.$emit('achApiCallSuccess');
    },
    imageViewedSuccess() {
      this.$emit('achApiCallSuccess');
    },
    async submitForm(validation) {
      const isValid = await validation.validate();
      // console.log('submitForm is running, validation:', validation, 'isValid:', isValid);
      if (!isValid) {
        return;
      }
      this.submitIsLoading = true;
      if (this.closeOrSendBack === 'sendBack') {
        let formData = {
          achRequestId: this.achId,
          note: this.note,
        };
        this.submitIsLoading = true;
        const data = await this.$store.dispatch('ach/submitAchSendBackToDept', formData);
        if (data.status == 200) {
          this.submitIsLoading = false;
          this.$emit('achApiCallSuccess');
        } else {
          this.submitIsLoading = false;
          this.$warning('Sending back failed.');
        }
      } else {
        let formData = {
          achRequestId: this.achId,
        };
        this.submitIsLoading = true;
        const data = await this.$store.dispatch('ach/submitAchCompleteDenial', formData);
        if (data.status == 200) {
          this.submitIsLoading = false;
          this.$emit('achApiCallSuccess');
        } else {
          this.submitIsLoading = false;
          this.$warning('Please try again.');
        }
      }
    },
  },
};

</script>
