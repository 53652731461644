<template>
  <div class="block">

    <div v-if="['AchAdministrator'].includes(this.curUserType)">

      <div class="block">
        <div class="columns marginless">
          <div class="section-header label-field column">
          Vendor banking information
          </div>
        </div>
        <div class="control">
          <button
            v-if="!bankDataIsShowing"
            class="button is-primary"
            :class="{ 'is-loading': bankInfoIsLoading }"
            @click.prevent="viewBankInfo"
          >
            Show bank information
          </button>
          <button
            v-if="bankDataIsShowing"
            class="button is-primary"
            :class="{ 'is-loading': bankInfoIsLoading }"
            @click.prevent="hideBankInfo"
          >
            Hide bank information
          </button>
        </div>
      </div>

      <!-- Financial institution information -->
      <div v-if="bankDataIsShowing">

        <div class="columns marginless">
          <div class="label-field column is-3">
            Vendor EIN
          </div>
          <div class="column is-9">
            {{ vendorEIN }}
          </div>
        </div>


        <div class="columns marginless">
          <div
            v-if="requestType !== 'Change to Existing Enrollment'"
            class="section-header label-field column"
          >
            Financial institution information
          </div>

          <div
            v-if="requestType === 'Change to Existing Enrollment'"
            class="section-header label-field column"
          >
            New financial institution information
          </div>
        </div>

        <div class="columns marginless">
          <div class="label-field column is-3">
            Name
          </div>
          <div class="column is-9">
            {{ bankData.bankName }}
          </div>
        </div>

        <div class="columns marginless">
          <div class="label-field column is-3">
            Routing number
          </div>
          <div class="column is-9">
            {{ bankData.routingNumber }}
          </div>
        </div>

        <div class="columns marginless">
          <div class="label-field column is-3">
            Account number
          </div>
          <div class="column is-9">
            {{ bankData.accountNumber }}
          </div>
        </div>

        <div class="columns marginless">
          <div class="label-field column is-3">
            Account type
          </div>
          <div class="column is-9">
            {{ bankData.accountType }}
          </div>
        </div>

        <bank-image
          :achId="achId"
          @image-viewed = "$emit('image-viewed')"
        />

        <hr>

        <!-- Old financial institution information -->
        <div v-if="requestType === 'Change to existing enrollment'">
          <div class="columns marginless">

            <div class="section-header label-field column">
              Old financial institution information
            </div>
          </div>

          <div class="columns marginless">
            <div class="label-field column is-3">
              Name
            </div>
            <div class="column is-9">
              {{ bankData.existingBankName }}
            </div>
          </div>

          <div class="columns marginless">
            <div class="label-field column is-3">
              Routing number
            </div>
            <div class="column is-9">
              {{ bankData.existingRoutingNumber }}
            </div>
          </div>

          <div class="columns marginless">
            <div class="label-field column is-3">
              Account number
            </div>
            <div class="column is-9">
              {{ bankData.existingAccountNumber }}
            </div>
          </div>

          <hr>
        </div>
      </div>
    </div>

  </div>
</template>

<script>

import BankImage from './BankImage.vue';

export default {
  name: "AchRequestFinancialInformation",
  components: {
    BankImage,
  },
  props: {
    achId: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      bankInfoIsLoading: false,
      bankDataIsShowing: false,
      bankData: {},
    };
  },
  computed: {
    requestType() {
      let values = {
        'NewEnrollment': 'New enrollment',
        'ChangeEnrollment': 'Change to existing enrollment',
        // 'CancelEnrollment': 'Cancel (stop) enrollment',
      };
      let value;
      if (this.$store.state.ach.achData) {
        value = values[this.$store.state.ach.achData.requestType];
      }
      return value;
    },
    vendorEIN() {
      return this.$store.state.ach.achData.einOrSSN;
    },
  },
  methods: {
    async viewBankInfo() {
      this.bankInfoIsLoading = true;
      let query = {
        humanReadableId: this.achId,
      };
      const { data } = await this.$store.dispatch('ach/getAchBank', query);
      this.$emit('show-bank-info');
      // console.log('Form.vue viewBankInfo is running, data:', data);
      this.bankData = data;
      this.bankInfoIsLoading = false;
      this.bankDataIsShowing = true;
    },
    hideBankInfo() {
      this.bankInfoIsLoading = false;
      this.bankDataIsShowing = false;
    },
  },
};
</script>
